import { ColumnBodyOptions } from "primereact/column";
import UiInputDate from "../../../../components/input-date/InputDate.component";
import { TableColumnTemplate } from "../../../../models/components/table/table-column.model";
import { BiometricRow } from "../../../../models/pages/biometrics/biometrics-modal.model";
import { BiometricsTemplate } from "../../../../services/biometrics/biometrics-template.service";
import { Validations } from "../../../../services/form/validations.service";
import { TableStyle } from "../../../../models/components/table/table.model";
import UiTable from "../../../../components/table/Table.component";
import { useTranslation } from "react-i18next";
import { Biometric, BiometricType } from "../../../../models/entities/biometrics.model";
import { Goal } from "../../../../models/entities/goal.model";
import { BiometricUpdateForm } from "../../../../models/pages/biometrics/biometrics-update.model";
import { BiometricsHelper } from "../../../../services/biometrics/biometrics-helper.service";

const BiometricsNestedList = ({
	biometrics,
	type,
	goals,
	editingRow,
	setEditingRow,
	updateForm,
	setUpdateForm,
	submitting,
	onDeleteBiometric,
	onUndeleteBiometric,
}: {
	biometrics: Map<string, Biometric[]>;
	type: BiometricType;
	goals: Goal[];
	editingRow: BiometricRow | null;
	setEditingRow: (row: BiometricRow | null) => void;
	updateForm: {
		value: BiometricUpdateForm["value"] | null;
		dateOn: BiometricUpdateForm["dateOn"] | null;
	};
	setUpdateForm: (form: {
		value: BiometricUpdateForm["value"] | null;
		dateOn: BiometricUpdateForm["dateOn"] | null;
	}) => void;
	submitting: boolean;
	onDeleteBiometric: (biometric: BiometricRow) => void;
	onUndeleteBiometric: (biometric: BiometricRow) => void;
}) => {
	const { t } = useTranslation("common");

	const rows = BiometricsHelper.getNestedListRows(biometrics, type, goals, editingRow);

	const markRowAsInactive = (row: BiometricRow): { [x: string]: unknown } => {
		return { "row-inactive": !row.active, "inline-form": true };
	};

	return (
		<UiTable
			dataKey="id"
			type={TableStyle.NESTED}
			useAsCard={false}
			rowClassName={markRowAsInactive}
			columns={[
				{
					field: "measurement",
					sortable: false,
					style: { width: BiometricsTemplate.columnsWidth.measurement },
					template: TableColumnTemplate.CUSTOM,
					templateOptions: {
						customTemplate: (row: BiometricRow, colBody: ColumnBodyOptions) =>
							colBody.rowIndex === rows.length - 1 ? (
								<span className="text">
									{t("PARTICIPANT.BIOMETRICS.LIST.firstMeasurement")}
								</span>
							) : (
								<></>
							),
					},
				},
				{
					field: "value",
					sortable: false,
					style: { width: BiometricsTemplate.columnsWidth.value },
					template: TableColumnTemplate.CUSTOM,
					templateOptions: {
						customTemplate: (row) =>
							BiometricsTemplate.templateValue(
								row,
								(value) => {
									setUpdateForm({
										...updateForm,
										value:
											updateForm.value && typeof updateForm.value === "object"
												? { ...updateForm.value, ...value }
												: value,
									});
								},
								submitting,
								updateForm.value
							),
					},
				},
				{
					field: "goal",
					sortable: false,
					style: { width: BiometricsTemplate.columnsWidth.goal },
					template: TableColumnTemplate.CUSTOM,
					templateOptions: {
						customTemplate: (row) => <></>,
					},
				},
				{
					field: "dateOn",
					sortable: false,
					style: { width: BiometricsTemplate.columnsWidth.dateOn },
					template: TableColumnTemplate.CUSTOM,
					templateOptions: {
						customTemplate: (row, colBody) => {
							if (!row.editing) {
								return <span className="text">{row.dateOn.formatted}</span>;
							}

							return (
								<UiInputDate
									id="dateOn"
									placeholder="ENUMS.BIOMETRICS.dateOn"
									name="dateOn"
									onChange={(e) =>
										setUpdateForm({
											...updateForm,
											dateOn: e.target.value as Date,
										})
									}
									validations={[Validations.required]}
									value={updateForm.dateOn ?? undefined}
									disabled={submitting}
									showTime
									showButtonBar={false}
									maxDate={new Date()}
								/>
							);
						},
					},
				},
				{
					field: "source",
					sortable: false,
					style: { width: BiometricsTemplate.columnsWidth.source },
					template: TableColumnTemplate.CUSTOM,
					templateOptions: {
						customTemplate: (row: BiometricRow) =>
							BiometricsTemplate.templateSource(t(row.source.formatted)),
					},
				},
				{
					field: "notes",
					sortable: false,
					style: { width: BiometricsTemplate.columnsWidth.notes },
					template: TableColumnTemplate.CUSTOM,
					templateOptions: {
						customTemplate: (row, colBody) =>
							BiometricsTemplate.templateNotes(
								row.active ? row.notes.formatted : "Deleted"
							),
					},
				},
				{
					field: "expandCollapse",
					sortable: false,
					style: { width: BiometricsTemplate.columnsWidth.expandCollapse },
				},
				{
					field: "edit",
					sortable: false,
					alignment: "right",
					style: { width: BiometricsTemplate.columnsWidth.edit },
					template: TableColumnTemplate.CUSTOM,
					templateOptions: {
						customTemplate: (row, colBody) => {
							return BiometricsTemplate.templateActions(
								row,
								(row) => {
									setEditingRow(row);
									setUpdateForm({
										value: row.value.value,
										dateOn: row.dateOn.value
											? new Date(row.dateOn.value)
											: null,
									});
								},
								() => {
									setEditingRow(null);
									setUpdateForm({
										value: null,
										dateOn: null,
									});
								},
								submitting,
								onDeleteBiometric,
								onUndeleteBiometric
							);
						},
					},
				},
			]}
			value={rows}
			paginator={false}
			pagination={{
				first: 0,
				rows: 10,
				page: 0,
				sortField: undefined,
				sortOrder: 1,
				search: undefined,
				filters: undefined,
			}}
		/>
	);
};

export default BiometricsNestedList;
