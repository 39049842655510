import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../services/endpoints/endpoints.service";
import { BiometricType, Biometric } from "../models/entities/biometrics.model";

export const useBiometrics = ({ participantId }: { participantId: number }) => {
	const queryClient = useQueryClient();
	const queryKey = ["biometrics", { participantId }];

	const query = useQuery(queryKey, async () => {
		const biometrics = await EndpointsService.dataRetriever
			.getAllBiometrics({
				config: {
					params: { participantId, showDeleted: true },
				},
			})
			.then(generateBiometricsMap);

		return biometrics;
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};

const generateBiometricsMap = (response: Biometric[]) => {
	const map = new Map<BiometricType, Biometric[]>();

	for (const i of response) {
		const biometric = i;
		const key = biometric.type;

		const existingBiometrics = map.get(key);
		if (existingBiometrics) {
			existingBiometrics.push(biometric);
		} else {
			map.set(key, [biometric]);
		}
	}

	return map;
};
