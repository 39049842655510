import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import UiInputText from "../input-text/InputText.component";
import UiSelect from "../select/Select.component";
import UiInputDate from "../input-date/InputDate.component";
import UiSelectButton from "../select-button/SelectButton.component";
import { Validations } from "../../services/form/validations.service";
import UiButton from "../button/Button";
import {
	AppointmentModalCoach,
	AppointmentModalFormData,
	AppointmentModalMode,
	AppointmentModalStoredData,
} from "../../models/components/appointment-modal.model";
import {
	Appointment,
	AppointmentCoach,
	AppointmentParticipant,
	AppointmentType,
	AppointmentTypes,
} from "../../models/entities/appointments.model";
import { Timezone } from "../../models/entities/participant.model";
import { List, ListOption } from "../../models/misc.model";
import { EndpointsService } from "../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import UiFieldMessage from "../field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../models/components/field-message.model";
import { Formatter } from "../../services/formatter/formatter.service";
import { FormApi } from "final-form";
import { TimezoneService } from "../../services/timezone/timezone.service";
import FormSection from "../form-section/FormSection.component";
import { AppointmentsService } from "../../services/appointments/appointments.service";

const AppointmentModalForm = ({
	mode,
	participant,
	coach,
	appointment,
	appointmentCoaches,
	appointmentTypes,
	sharedData,
	onNext,
}: {
	mode: AppointmentModalMode;
	participant: AppointmentParticipant;
	coach: AppointmentModalCoach;
	appointment: Appointment | null;
	appointmentCoaches: AppointmentCoach[];
	appointmentTypes: AppointmentTypes[];
	sharedData: AppointmentModalStoredData | null;
	onNext: (e: AppointmentModalStoredData) => void;
}) => {
	const { t } = useTranslation("common");

	const [formData, setFormData] = useState<AppointmentModalFormData>({
		kannactId: sharedData?.formData?.kannactId ?? participant.kannactId ?? "",
		calendarId:
			sharedData?.formData?.calendarId ?? appointment?.coachId ?? coach.calendarId ?? null,
		appointmentType: sharedData?.formData?.appointmentType ?? appointment?.type ?? null,
		dateOn:
			sharedData?.formData?.dateOn ??
			(appointment?.datetime ? new Date(appointment.datetime) : null),
		timezone:
			sharedData?.formData?.timezone ??
			TimezoneService.getGs2Value(appointment?.participant.timezone) ??
			(participant.timezone && participant.timezone !== Timezone.NA
				? participant.timezone
				: null),
		time: sharedData?.formData?.time ?? appointment?.datetime ?? null,
	});

	// Availables dates
	const [disabledDates, setDisabledDates] = useState<Date[]>(sharedData?.disabledDates ?? []);
	const [datesMessage, setDatesMessage] = useState<{
		submitting: boolean;
		message: string | null;
	}>({
		submitting: false,
		message: null,
	});

	// Time slots
	const [timeSlots, setTimeSlots] = useState<List>(sharedData?.timeSlots ?? []);
	const [timeSlotsMessage, setTimeSlotsMessage] = useState<{
		submitting: boolean;
		message: string | null;
	}>({
		submitting: false,
		message: null,
	});

	// First time load
	useEffect(() => {
		if (formData.appointmentType && formData.calendarId && !sharedData?.disabledDates)
			getDates();
		if (formData.dateOn && !sharedData?.timeSlots) getTimeSlots(formData.dateOn);
	}, []);

	// When appointmentType & calendarId exists (get dates)
	useEffect(() => {
		if (formData.appointmentType && formData.calendarId && !sharedData?.disabledDates)
			getDates();
	}, [formData.appointmentType, formData.calendarId]);

	// Field changed: appointment type -> Update
	const appointmentTypeChanged = (
		value: AppointmentType,
		form: FormApi<
			Record<string, AppointmentModalFormData>,
			Partial<Record<string, AppointmentModalFormData>>
		>
	) => {
		// Update form
		if (form.getFieldState("dateOn")) form.resetFieldState("dateOn");
		if (form.getFieldState("time")) form.resetFieldState("time");
		setFormData({
			...formData,
			appointmentType: value,
			dateOn: null,
			time: null,
		});
	};

	const calendarIdChanged = (
		value: string,
		form: FormApi<
			Record<string, AppointmentModalFormData>,
			Partial<Record<string, AppointmentModalFormData>>
		>
	) => {
		// Update form
		if (form.getFieldState("dateOn")) form.resetFieldState("dateOn");
		if (form.getFieldState("time")) form.resetFieldState("time");
		setFormData({
			...formData,
			calendarId: value,
			dateOn: null,
			time: null,
		});
	};

	// Available dates
	const getDates = async () => {
		const today = new Date();
		const date = formData.dateOn ?? today;

		// Update state
		setDisabledDates([]);
		setDatesMessage({ submitting: true, message: null });

		// Get dates
		await EndpointsService.appointments
			.getDates({
				config: {
					params: {
						year: date.getFullYear(),
						month: date.getMonth() + 1,
						appointmentType: formData.appointmentType!,
						coachId: AppointmentsService.getAppointmentCoach(
							appointmentCoaches,
							formData.calendarId!
						)!.id, // Calendar ID
						timezone: coach.timezone,
					},
				},
			})
			.then((response) => {
				/* Set available dates */
				const lastDayOfMonth = new Date(
					date.getFullYear(),
					date.getMonth() + 1,
					0
				).getDate();
				const disabledDates: Date[] = [];
				// Iterate month days
				for (let i = 1; i < lastDayOfMonth + 1; i++) {
					const tmpDate = new Date(date.getFullYear(), date.getMonth(), i);
					const dateIsAvailable = response.find(
						(x) => Formatter.dateISOToUTC(x.date)?.getTime() === tmpDate.getTime()
					);
					if (!dateIsAvailable) {
						disabledDates.push(tmpDate);
					}
				}

				setDisabledDates(disabledDates);
				setDatesMessage({ submitting: false, message: null });
			})
			.catch(() => {
				setDatesMessage({
					submitting: false,
					message: "APPOINTMENTS.HTTP_MESSAGES.AVAILABLE_DATES_500",
				});
			});
	};

	// Field changed: date
	const dateOnChanged = async (
		date: Date | null,
		form: FormApi<
			Record<string, AppointmentModalFormData>,
			Partial<Record<string, AppointmentModalFormData>>
		>
	) => {
		// Set default states
		if (form.getFieldState("time")) form.resetFieldState("time");
		setFormData({
			...formData,
			time: null,
			dateOn: date,
		});
		setTimeSlots([]);
		setTimeSlotsMessage({ submitting: !!date, message: null });

		// Get available time slots
		if (date) getTimeSlots(date);
	};

	// Available time slots
	const getTimeSlots = async (date: Date) => {
		const year = `${date.getFullYear()}`;
		const month =
			date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
		const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
		await EndpointsService.appointments
			.getTimeSlots({
				config: {
					params: {
						appointmentType: formData.appointmentType!,
						coachId: AppointmentsService.getAppointmentCoach(
							appointmentCoaches,
							formData.calendarId!
						)!.id, // Calendar ID
						date: `${year}-${month}-${day}`,
						timezone: coach.timezone,
					},
				},
			})
			.then((response) => {
				setTimeSlotsMessage({ submitting: false, message: null });

				if (response.length > 0) {
					setTimeSlots(
						response
							.filter((x) => new Date(x.datetime).getTime() >= new Date().getTime())
							.map((x) => {
								const coachTime = Formatter.dateISOToString(x.datetime, {
									hour: "2-digit",
									minute: "2-digit",
									hour12: true,
									timeZoneName: "shortGeneric",
								});

								let ptTime: string | null = null;
								if (formData.timezone && formData.timezone !== Timezone.NA) {
									ptTime =
										Formatter.dateISOToString(x.datetime, {
											hour: "2-digit",
											minute: "2-digit",
											hour12: true,
											timeZoneName: "shortGeneric",
											timeZone: formData.timezone!,
										}) ?? null;
								}

								return new ListOption({
									id: x.datetime,
									label: `${ptTime ?? Formatter.NO_DATA} / ${coachTime}`,
								});
							})
					);
				}
			})
			.catch((error: AxiosError) => {
				const errorMsg =
					error.response?.status === 400
						? "UI_COMPONENTS.FIELD_MESSAGE.HTTP.400"
						: error.response?.status === 404
							? "APPOINTMENTS.HTTP_MESSAGES.TIME_SLOTS_404"
							: "APPOINTMENTS.HTTP_MESSAGES.TIME_SLOTS_500";
				setTimeSlotsMessage({ submitting: false, message: errorMsg });
			});
	};

	// Field changed: participant's timezone --> Update time slots description
	const timezoneChanged = (timezone: string) => {
		if (timeSlots.length > 0) {
			// Update the time (participant's timezone)
			setTimeSlots(
				timeSlots.map((x) => {
					const coachTime = Formatter.dateISOToString(x.id as string, {
						hour: "2-digit",
						minute: "2-digit",
						hour12: true,
						timeZoneName: "shortGeneric",
					});
					const ptTime = Formatter.dateISOToString(x.id as string, {
						hour: "2-digit",
						minute: "2-digit",
						hour12: true,
						timeZoneName: "shortGeneric",
						timeZone: timezone,
					});
					x.label = `${ptTime} / ${coachTime}`;
					return x;
				})
			);
		}
	};

	return (
		<Form
			onSubmit={() => onNext({ formData, disabledDates, timeSlots })}
			initialValues={formData}
			render={({ handleSubmit, form, submitting }) => (
				<form onSubmit={handleSubmit}>
					{/* Reschedule info message */}
					{mode === AppointmentModalMode.RESCHEDULE && (
						<p>
							{t("APPOINTMENTS.MODAL_APPOINTMENT.MSG_INFO", {
								date: Formatter.date(appointment?.datetime, {
									day: "2-digit",
									month: "long",
									year: "numeric",
									weekday: "long",
								}),
								ptTime: Formatter.dateISOToString(appointment?.datetime, {
									hour: "2-digit",
									minute: "2-digit",
									hour12: true,
									timeZoneName: "shortGeneric",
									timeZone: participant.timezone,
								}),
								coachTime: Formatter.dateISOToString(appointment?.datetime, {
									hour: "2-digit",
									minute: "2-digit",
									hour12: true,
									timeZoneName: "shortGeneric",
								}),
							})}
						</p>
					)}

					<FormSection>
						{/* Kannact ID */}
						<div className="row col-12 col-sm-12 col-md-8 col-lg-4">
							<UiInputText
								id="kannactId"
								label="APPOINTMENTS.MODAL_APPOINTMENT.FORM_FIELDS.kannactId"
								name="kannactId"
								onChange={(e) =>
									setFormData({ ...formData, kannactId: e.target.value })
								}
								disabled={true}
							/>
						</div>

						{/* Appointment type */}
						<div className="row col-12 col-sm-12 col-md-8 col-lg-6">
							<UiSelect
								id="appointmentType"
								label="APPOINTMENTS.MODAL_APPOINTMENT.FORM_FIELDS.appointmentType"
								name="appointmentType"
								onChange={(e) => appointmentTypeChanged(e.target.value, form)}
								disabled={submitting || mode === AppointmentModalMode.RESCHEDULE}
								validations={[Validations.required]}
								options={
									mode === AppointmentModalMode.RESCHEDULE
										? appointmentTypes.map(
												(x) =>
													new ListOption({
														id: x.type,
														label: `${t(`ENUMS.APPOINTMENTS_TYPE.${x.type}`)} (${
															x.durationInMinutes
														} min + ${x.totalPaddingInMinutes} buffer)`,
													})
											)
										: appointmentTypes
												.filter(
													(x) =>
														x.type ===
															AppointmentType.KANNACT_INTRO_CALL ||
														x.type ===
															AppointmentType.KANNACT_WHOLE_HEALTH_INTRO_CALL ||
														x.type ===
															AppointmentType.TECH_SUPPORT_CALL ||
														x.type ===
															AppointmentType.COACHING_CALL_10 ||
														x.type === AppointmentType.COACHING_CALL ||
														x.type ===
															AppointmentType.COACHING_CALL_30_15 ||
														x.type ===
															AppointmentType.KANNACT_WARM_HANDOFF ||
														x.type ===
															AppointmentType.COACHING_CALL_45 ||
														x.type ===
															AppointmentType.NEW_PATIENT_CONSULTATION_20 ||
														x.type ===
															AppointmentType.NEW_PATIENT_CONSULTATION_45 ||
														x.type ===
															AppointmentType.FOLLOWUP_VISIT_20_10 ||
														x.type ===
															AppointmentType.COVERAGE_BENEFITS_REVIEW ||
														x.type ===
															AppointmentType.INFORMATIONAL_CALL ||
														x.type ===
															AppointmentType.INFORMATION_CALL_10_5
												)
												.map(
													(x) =>
														new ListOption({
															id: x.type,
															label: `${t(
																`ENUMS.APPOINTMENTS_TYPE.${x.type}`
															)} (${x.durationInMinutes} min + ${
																x.totalPaddingInMinutes
															} buffer)`,
														})
												)
												.sort((a, b) => {
													// sort by name asc
													if (a.label < b.label) return -1;
													if (a.label > b.label) return 1;
													return 0;
												})
								}
							/>
						</div>

						{/* Appointment coach / calendarId */}
						{formData.appointmentType && (
							<div className="row col-12 col-sm-12 col-md-8 col-lg-4">
								<UiSelect
									id="calendarId"
									label="APPOINTMENTS.MODAL_APPOINTMENT.FORM_FIELDS.calendarId"
									name="calendarId"
									onChange={(e) => calendarIdChanged(e.target.value, form)}
									disabled={
										submitting || mode === AppointmentModalMode.RESCHEDULE
									}
									validations={[Validations.required]}
									options={appointmentCoaches
										.filter(
											(obj1, i, arr) =>
												arr.findIndex((obj2) => obj2.id === obj1.id) === i
										)
										.map((x) => new ListOption({ id: x.id, label: x.name }))}
								/>
							</div>
						)}

						<div className="row">
							{/* Date */}
							<div className="col-12 col-sm-12 col-md-12 col-lg-6">
								{/* Messages: error */}
								{datesMessage.message && (
									<UiFieldMessage
										severity={FieldMessageSeverity.DANGER}
										label={datesMessage.message}
									/>
								)}

								{/* Messages: loading */}
								{datesMessage.submitting && (
									<UiFieldMessage
										severity={FieldMessageSeverity.LOADING}
										label="APPOINTMENTS.HTTP_MESSAGES.AVAILABLE_DATES_LOADING"
									/>
								)}

								{!datesMessage.message &&
									!datesMessage.submitting &&
									formData.appointmentType && (
										<UiInputDate
											id="dateOn"
											label="APPOINTMENTS.MODAL_APPOINTMENT.FORM_FIELDS.dateOn"
											name="dateOn"
											onChange={(e) =>
												dateOnChanged(e.target.value as Date | null, form)
											}
											disabled={
												submitting ||
												datesMessage.submitting ||
												!formData.appointmentType
											}
											minDate={new Date()}
											disabledDates={disabledDates}
											validations={[Validations.required]}
											inline={true}
											showButtonBar={false}
										/>
									)}
							</div>

							{formData.dateOn && (
								<div className="col-12 col-sm-12 col-md-12 col-lg-6">
									{/* Participant's time zone */}
									<div className="row col-12 col-sm-12 col-md-12 col-lg-7">
										<UiSelect
											id="timezone"
											label="APPOINTMENTS.MODAL_APPOINTMENT.FORM_FIELDS.timezone"
											name="timezone"
											onChange={(e) => {
												setFormData({
													...formData,
													timezone: e.target.value,
												});
												timezoneChanged(e.target.value);
											}}
											disabled={submitting}
											validations={[Validations.required]}
											options={[
												{
													id: Timezone.US_Alaska,
													label: `ENUMS.TIMEZONES.US_Alaska`,
												},
												{
													id: Timezone.US_Arizona,
													label: `ENUMS.TIMEZONES.US_Arizona`,
												},
												{
													id: Timezone.US_Central,
													label: `ENUMS.TIMEZONES.US_Central`,
												},
												{
													id: Timezone.US_Eastern,
													label: `ENUMS.TIMEZONES.US_Eastern`,
												},
												{
													id: Timezone.US_Hawaii,
													label: `ENUMS.TIMEZONES.US_Hawaii`,
												},
												{
													id: Timezone.US_Mountain,
													label: `ENUMS.TIMEZONES.US_Mountain`,
												},
												{
													id: Timezone.US_Pacific,
													label: `ENUMS.TIMEZONES.US_Pacific`,
												},
											]}
										/>
									</div>

									{/* Time */}
									<div className="row col-12 col-sm-12 col-md-12 col-lg-10 time-slots-container">
										{/* Messages: error */}
										{timeSlotsMessage.message && (
											<UiFieldMessage
												severity={FieldMessageSeverity.DANGER}
												label={timeSlotsMessage.message}
											/>
										)}

										{/* Messages: loading */}
										{timeSlotsMessage.submitting && (
											<UiFieldMessage
												severity={FieldMessageSeverity.LOADING}
												label={
													"APPOINTMENTS.HTTP_MESSAGES.TIME_SLOTS_LOADING"
												}
											/>
										)}

										{/* Messages: no data */}
										{!timeSlotsMessage.message &&
										!timeSlotsMessage.submitting &&
										timeSlots.length === 0 ? (
											<UiFieldMessage
												severity={FieldMessageSeverity.INFO}
												label={
													"APPOINTMENTS.HTTP_MESSAGES.TIME_SLOTS_EMPTY"
												}
											/>
										) : (
											<UiSelectButton
												id="time"
												className="vertical"
												name="time"
												onChange={(e) =>
													setFormData({ ...formData, time: e.value })
												}
												options={timeSlots}
												validations={[Validations.required]}
											/>
										)}
									</div>
								</div>
							)}
						</div>
					</FormSection>

					{/* Actions: Next (confirmation) */}
					<div className="action-buttons">
						<UiButton
							id="modalNextBtn"
							label="UI_COMPONENTS.BUTTONS.NEXT"
							className="p-button-rounded"
							type="submit"
							disabled={
								timeSlotsMessage.submitting ||
								!form.getState().valid ||
								!formData.time
							}
						></UiButton>
					</div>
				</form>
			)}
		/>
	);
};

export default AppointmentModalForm;
